import React from 'react'
import { Route } from 'react-router-dom'
import { Redirect } from 'react-router'
import { ConnectedRouter } from 'connected-react-router/immutable'

import { asyncComponent } from '@common'
// import PaymentMethod from '../views/paymentMethod'
// import OrderDetails from '../views/orderDetails'
// import ApplyInstruction from '../views/applylimit-instruction'

import { history } from '../store'
import Main from '../views/main'
import { prerenderDynamics } from '@/__prefix__/prerenders'

const PaymentMethod = asyncComponent(() =>
  import(
    /* webpackChunkName: 'openPay/old/PaymentMethod' */ '../views/paymentMethod'
  ),
)
const ApplyInstruction = asyncComponent(() =>
  import(
    /* webpackChunkName: 'openPay/old/ApplyInstruction' */ '../views/applylimit-instruction'
  ),
)

const ApplyLimits = asyncComponent(() =>
  import(
    /* webpackChunkName: 'openPay/old/ApplyLimits' */ '../views/applyLimits'
  ),
)

const ApplyLimitResult = asyncComponent(() =>
  import(
    /* webpackChunkName: 'openPay/old/ApplyLimitResult' */ '../views/applyLimitResult'
  ),
)

// const ApplyLimitInstruction = asyncComponent(() =>
//   import(/* webpackChunkName: 'openPay/old/ApplyLimitInstruction' */ '../views/applyLimit-instruction')
// )

const Ecommerce = asyncComponent(() =>
  import(/* webpackChunkName: 'openPay/old/Ecommerce' */ '../views/ecommerce'),
)

const PaymentResult = asyncComponent(() =>
  import(
    /* webpackChunkName: 'openPay/old/PaymentResult' */ '../views/paymentResult'
  ),
)

// const Register = asyncComponent(() =>
//   import(/* webpackChunkName: 'openPay/old/Register' */ '../views/register'),
// )

const CouponCenter = asyncComponent(() =>
  import(
    /* webpackChunkName: 'openPay/old/CouponCenter' */ '../views/coupon-center'
  ),
)

const CouponDetail = asyncComponent(() =>
  import(
    /* webpackChunkName: 'openPay/old/CouponDetail' */ '../views/coupon-center/views/coupon-detail/'
  ),
)

const OrderPending = asyncComponent(() =>
  import(
    /* webpackChunkName: 'openPay/old/OrderPending' */ '../views/order-pending'
  ),
)

const PaymentContinue = asyncComponent(() =>
  import(
    /* webpackChunkName: 'openPay/old/PaymentContinue' */ '../views/pay-continue'
  ),
)

const Terms = asyncComponent(() =>
  import(/* webpackChunkName: 'openPay/old/Terms' */ '../views/terms'),
)

// 站内订单列表页
const OrderList = asyncComponent(() =>
  import(/* webpackChunkName: 'openPay/old/orderList' */ '../views/order-list'),
)

const ApprovalResult = asyncComponent(() =>
  import(
    /* webpackChunkName: 'openPay/old/approvalResult' */ '../views/approvalResult'
  ),
)

const CreateOrderFailed = asyncComponent(() =>
  import(
    /* webpackChunkName: 'openPay/old/CreateOrderFailed' */ '../views/CreateOrderFailed'
  ),
)

export const AppRouter = () => (
  <ConnectedRouter history={history}>
    <Main>
      <Route
        exact
        path="/openPay.html"
        render={() => <Redirect to={`/login${window.location.search}`} />}
      />
      <Route
        exact
        path="/login"
        render={() => <Redirect to={`/signin${window.location.search}`} />}
      />
      <Route exact path="/signin" component={prerenderDynamics.old.Login} />
      {/* <Route exact path="/register" component={Register} /> */}
      <Route
        exact
        path="/applylimit-instruction"
        component={ApplyInstruction}
      />
      <Route
        exact
        path="/orderDetails"
        component={prerenderDynamics.old.OrderDetails}
      />
      <Route path="/paymentMethod/" component={PaymentMethod} />
      <Route path="/applyLimits/:step/:applyStatus" component={ApplyLimits} />
      <Route exact path="/applyLimitResult" component={ApplyLimitResult} />
      <Route exact path="/ecommerce/:path" component={Ecommerce} />
      <Route path="/paymentResult/" component={PaymentResult} />
      <Route path="/pay-continue/" component={PaymentContinue} />
      <Route path="/coupon-center/" exact component={CouponCenter} />
      <Route path="/coupon-center/coupon-detail" component={CouponDetail} />
      <Route path="/order-pending/" exact component={OrderPending} />
      <Route path="/terms/" component={Terms} />
      <Route path="/order-list/" component={OrderList} />
      <Route path="/approvalResult" component={ApprovalResult} />
      <Route path="/CreateOrderFailed" component={CreateOrderFailed} />
    </Main>
  </ConnectedRouter>
)
