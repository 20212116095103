import './am-reset.scss'
import './style.scss'
import './config/axios.config'
import '../../__prefix__/setRem'

import React, { Component } from 'react'
import { Provider } from 'react-redux'
// import { PersistGate } from 'redux-persist/integration/react'
import NiceModal from '@ebay/nice-modal-react'
import { helpers, Basic } from '@common'
import { PopupFrame } from '@Arui'
import { store } from './store'
import { AppRouter } from './config/router'

let locale = {
  // in: helpers.i18n.load([() => import('./assets/i18n/in.json')]),
  in: require('./assets/i18n/in.json'),
  en: helpers.i18n.load([() => import('./assets/i18n/en.json')]),
  ms: helpers.i18n.load([() => import('./assets/i18n/ms.json')]),
  vi: helpers.i18n.load([() => import('./assets/i18n/vi.json')]),
}

export default class App extends Component {
  render() {
    return (
      <>
        <Basic locale={locale}>
          <Provider store={store}>
            <NiceModal.Provider>
              <AppRouter />
            </NiceModal.Provider>
          </Provider>
        </Basic>
        <PopupFrame />
      </>
    )
  }
}
if (process.env.NODE_ENV === 'development') {
  window.__store__ = store
}
